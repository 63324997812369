<template>
  <ValidationProvider
    :rules="validationRules"
    class="question single-answer column is-half"
    tag="div"
    v-slot="{ errors }"
  >
    <div class="question-text">
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <template v-if="categories">
          <b-field>
            <b-input
              v-model="value[`${question.qid}`]"
              class="is-hidden"
            ></b-input>
            <b-autocomplete
              @keydown.native="keyHandler"
              class="is-borderless"
              :field="`answer.${lang}`"
              :data="categories"
              :placeholder="$t('dropdown.placeholder')"
              group-field="title"
              group-options="answers"
              icon-right="chevron-down"
              v-model="keyword"
              expanded
              open-on-focus
              :value="value[`${question.qid}`]"
              clearable
              append-to-body
              @select="onChange"
            >
              <template #empty>{{ $t("dropdown.empty_result") }}</template>
              <template #group="group">
                <span
                  v-if="
                    categories[group.index].display_title &&
                    categories[group.index].answers.length > 0
                  "
                  class="has-text-weight-bold"
                >
                  {{ group.group[lang] || Object.values(group.group)[0] }}
                </span>
                <hr
                  v-else-if="categories[group.index].answers.length > 0"
                  class="my-1"
                />
              </template>
            </b-autocomplete>
          </b-field>
        </template>
        <template v-else>
          <b-field>
            <b-input
              v-model="value[`${question.qid}`]"
              class="is-hidden"
            ></b-input>
            <b-autocomplete
              @keydown.native="keyHandler"
              class="is-borderless"
              :field="`answer.${lang}`"
              :data="filteredDataObj"
              :placeholder="$t('dropdown.placeholder')"
              icon-right="chevron-down"
              v-model="keyword"
              expanded
              open-on-focus
              append-to-body
              :value="value[`${question.qid}`]"
              clearable
              @select="onChange"
            >
              <template #empty>{{ $t("dropdown.empty_result") }}</template>
            </b-autocomplete>
            <!-- <b-select
              @input="onChange"
              class="is-borderless"
              expanded
              v-model="value[`${question.qid}`]"
              placeholder="Click to select..."
            >
              <option
                :key="answer.id"
                :value="answer.code"
                v-for="answer in question.answers"
              >
                {{ answer.answer[lang] }}
              </option>
            </b-select> -->
          </b-field>
        </template>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { QuestionMixin } from "@/mixins/question";
import { UnFocusMixin } from "@/mixins/unfocus";
import { BlockRandomizationMixin } from "@/mixins/block-randomization";
export default {
  name: "DropdownList",
  mixins: [QuestionMixin, UnFocusMixin, BlockRandomizationMixin],
  data() {
    return {
      keyword: "",
    };
  },
  methods: {
    async keyHandler(e) {
      if (e.key === "Enter") {
        await this.$emit("is-enter");
      }
    },
    async onChange(option) {
      await this.$emit("input", {
        [this.question.qid]: option?.code,
      });
      await this.$emit("move-next");
    },
  },
  computed: {
    filteredDataObj() {
      return this.question.answers.filter((option) => {
        return (
          option.answer[this.lang]
            .toString()
            .toLowerCase()
            .indexOf(this.keyword.toLowerCase()) >= 0
        );
      });
    },
    customValidationRules() {
      return {
        mandatory: {
          type: "DL",
          value: this.value[`${this.question.qid}`],
        },
      };
    },
  },
  created() {
    setTimeout(() => {
      if (!this.value[this.question.qid]) {
        this.$emit("input", { [this.question.qid]: "" });
      }
      const selectedOption = this.question.answers.find(
        (option) => option.code === this.value[this.question.qid]
      );
      if (selectedOption) {
        this.keyword = selectedOption.answer[this.lang];
        this.unFocus();
      }
    }, 300);
  },
};
</script>
<style lang="scss" scoped>
.card {
  overflow: unset;
}
</style>
